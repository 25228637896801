<template>
  <v-container tag="section" fluid>
    <v-row dense>
      <v-col v-for="pagina in paginas" :key="pagina.nome" xs="6" sm="4" xl="3">
        <v-card
          min-width="200px"
          height="100%"
          class="mx-2 d-flex justify-center"
          raised
          :to="pagina.path"
        >
          <v-card-title primary-title class="d-flex justify-center">
            <div>
              <p class="d-flex justify-center">
                <v-icon size="70">
                  {{ pagina.icon }}
                </v-icon>
              </p>
              <p class="d-flex justify-center">
                <span class="title font-weight-medium">
                  {{ pagina.titulo }}
                </span>
              </p>
            </div>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  data: () => ({
    paginas: [
      {
        titulo: 'USUÁRIOS',
        path: '/administracao/usuarios',
        icon: 'mdi-account-cog-outline'
      },
      // {
      //   titulo: 'RELATÓRIOS',
      //   path: '/administracao/relatorios',
      //   icon: 'mdi-clipboard-outline'
      // },
      // {
      //   titulo: 'RELATÓRIOS :: STATUS FALHA',
      //   path: '/administracao/relatorios-criados-status-falha',
      //   icon: 'mdi-clipboard-alert-outline'
      // },
      {
        titulo: 'IMPORTAÇÕES DE DADOS',
        path: '/administracao/importacoes-dados/historico',
        icon: 'mdi-database-arrow-up'
      },
      {
        titulo: 'VERSIONAMENTO DE BASES',
        path: '/administracao/versionamento-bases/',
        icon: 'mdi-database-cog'
      },
      {
        titulo: 'IMPORTAÇÕES INDGER',
        path: '/administracao/importacoes-indger/historico',
        icon: 'mdi-database-plus'
      },
      {
        titulo: 'GOVERNANÇA',
        path: '/administracao/governanca',
        icon: 'mdi-bank'
      },
      {
        titulo: 'TIPOS DE SERVIÇOS',
        path: '/administracao/tipos-servicos',
        icon: 'mdi-toolbox-outline'
      },
    ]
  })
};

</script>